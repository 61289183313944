<template>
	<u-i-node
		v-for="node in root.children"
		:key="node.id"
		:node="node"
	/>
</template>

<script>
import {Node} from "@/tree/models/Node";
import UINode from "@/tree/components/UINode.vue";
import {AbstractProvider} from "@/tree/lib/AbstractProvider";

export default {
	name: "UITree",
	components: {UINode},
	props: {
		source: {
			type: Array,
			default: () => []
		},
		provider: {
			type: AbstractProvider,
			default: {}
		}
	},
	data() {
		return {
			root: {},
			nodesList: {},
		};
	},
	mounted() {
		// Define tree root = without title and always with the id 0
		this.root = new Node(0);
		this.buildTree();
	},
	methods: {
		/**
		 * TODO: use external buffer and source manager
		 */
		buildTree() {

			let source = this.source;
			let unassigned = [];
			let buffer = { [this.root.id]: this.root };
			
			while (source.length) {

				this.provider.load(source).forEach(node => {

					let parentId = this.provider.getParent(node);
					let nodeId = this.provider.getId(node);
					let title = this.provider.getTitle(node);

					if (buffer[parentId]) {
						let treeNode = new Node(nodeId, title);
						buffer[parentId].addChild(treeNode);
						buffer[nodeId] = treeNode;
					} else {
						unassigned.push(node);
					}

				});

				source = unassigned;
				unassigned = [];
			}

		},
	}
};

</script>
<style scoped>
</style>